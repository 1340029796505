.FlyerCardMenu {
  position: absolute;
  right: 10px;
  height: auto;
  width: calc(100% - 26px);
  display: flex;
  /* justify-content: flex-end; */
  justify-content: space-between;
  background-color: whitesmoke;
  z-index: 1;
}

.FlyerForm .FlyerCardMenu {
  position: inherit;
  display: inline;
}

.FlyerCardMenuClosed {
  position: relative;
}

.FlyerCardMenuClosed .handle {
  position: absolute;
  right: -6px;
}

.handle {
  padding: 6px 10px;
  /* width: 100%; */
  text-align: right;
  color: rgb(46, 46, 46);
  /* color: whitesmoke; */
  /* background-color: whitesmoke; */
  /* background-image: linear-gradient(0deg, rgba(0,0,0,0), rgba(255,255,255,.3)); */
}

.FlyerCardMenu .active {
  color: green;
}

.inline-float-right {
  display: inline-table;
  float: right;
}
