.MainHeader {
  padding: 16px;
  margin-bottom: 1px;
  color: white;
  height: 67px;
  display: flex;
  align-items: center;
  background-color: rgb(46, 46, 46);
  width: 100%;
  z-index: 1;
}

.MainHeader.dbl-height {
  height: 134px;
}

.MainHeader.no-height {
  height: 0;
  padding: 0;
}

.MainHeader .header-link button, a.header-link {
  color: white;
  font-size: 14px;
}
