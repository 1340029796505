.Intro h3 {
  margin-top: auto;
}

.Intro .CentralContainer h3 {
   margin-bottom: 9.38px;
}

.Intro p {
  margin-top: 0;
}


.flex-bullets {
  display: flex;
  flex-direction: column;
  align-items: top;
}

.flex-bullets .Sword {
  position: relative;
  top: 10px;
  margin-right: 2px;
  align-self: center;
}

.flex-bullets .flourish {
  position: relative;
  top: -6px;
  align-self: center;
}

.flourish {
  width: 32px;
  align-self: center;
}


.center-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top:16px;
  margin-bottom: 8px;
}
