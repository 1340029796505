html,body {
  /* position: fixed; */
  height:100%;
  width: 100%;
  padding:0;
  margin:0;
}

#root {
  position: fixed;
  height: 100%;
  width: 100%;
}

body * {
    box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body form {
  padding: 24px;
  background-color: whitesmoke;
}

body form.dark-mode {
  background-color: rgb(46, 46, 46);
  color: white;
}

body form.header-form {
  max-width: 100%;
  padding: 16px;
  padding-top: 54px;
}

/* body p.Profile--location svg {
  color: blue;
} */


div.margin-bottom-8 {
  margin-bottom: 8px;
}

div.margin-bottom-16 {
  margin-bottom: 16px;
}

p.no-top-margin {
  margin-top: 0;
}

a {
  cursor: pointer;
}

