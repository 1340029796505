
body {
  font-family: 'Heebo', 'Arial';
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  background-color: dimgrey;
  font-size: 12px;
}

a {
  text-decoration: none;
   /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  transition: ease;
}

button, a {
  transition: .2s ease;
}

button:focus, a:focus, button:hover, a:hover {
  outline: none;
  box-shadow: none;
}

h1, h2{
  margin-top: 0;
  /* margin-bottom: 0; */
  padding-top: 0;
  font-size: 14px;
}


h3 {
  margin-top: 0;
  font-size: 12px;
}

ul li {
  list-style: none;
}

.App {
  width: 100%;
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.username::before {
  content: '@';
}

.html-display-p p {
  margin: 0;
  padding: 0;
}

.center-text {
  text-align: center;
}


.ActionButton {
  display: inline-block;
  line-height: 24px;
  padding: 0px 8px;
  color: white;
  background-color:green;
  margin-left: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  text-decoration: none;
}

.Landing .About a:not(.ActionButton),
.Landing .Intro a:not(.ActionButton),
.Landing form .form-controls a,
form .form-controls a:not(.ActionButton),
.FlyerCard .Accordian--content a,
.FlyerCard .Comment a  {
  color: blue;
}

.Landing .About a:not(.ActionButton),
.Landing .Intro a:not(.ActionButton){
  white-space: nowrap;
}

.Landing .About a:not(.ActionButton):hover,
.Landing .Intro a:not(.ActionButton):hover,
.Landing form a:hover,
form a:not(.ActionButton):hover
.FlyerCard .Accordian--content a:hover {
  color: rgba(46, 46, 46, 1);
  text-decoration: underline;
}

.FetchingAdditional--container a {
  /* color: rgba(46, 46, 46, 1); */
  color: darkgrey;
  cursor: pointer;
}

.FetchingAdditional--container a:hover {
  /* color: black; */
  color: whitesmoke
}


.white-smoke-bg {
  background-color: whitesmoke;
}

.sticky {
  position: sticky;
  top: 1px;
}

.Tag, button {
  display: inline-block;
  line-height: 24px;
  max-height: 24px;
  padding: 0px 8px;
  margin-left: 0;
  border: none;
}

.Tag {
  color: white;
  background-color: darkgray;
  margin-right: 1px;
  margin-bottom: 1px;
}
