.FlyerCard.cancelled .Card--body h2,
.FlyerCard.cancelled .Card--body .Flyer--dates,
.FlyerCard.cancelled .Card--body .Flyer--location,
.FlyerCard.cancelled .Card--body .Accordian--content {
  text-decoration: line-through;
}

.Card {
  margin-bottom: 20px;
  background: whitesmoke;
}

.Card--body {
  padding: 16px;
  padding-bottom: 0;
  position: relative;
}

.Card--header i {
  line-height: 24px;
  font-size: 22px;
}

.Card--header h2 {
  margin-bottom: 12px;
}

.Card--header .Tag {
  margin-bottom: 8px;
}

.Card .Accordian {
  margin-top: 12px;
}

/* Flyer */
.Flyer--image {
  width: 100%;
}

.Flyer--dates svg {
  margin-right: 4px;
  color: orangered;
}

.Flyer--location svg {
  margin-right: 4px;
  color: blue;
}

.Flyer--location.cancelled {
  text-decoration: line-through;
}

.DateOf {
  margin-right: 4px;
}

/* .Flyer--dates, .Flyer--location {
  margin: 10px 0;
} */


.Flyer--icon-status.Tag {
  background-color: red;
  margin-right: 4px;
}

.red-bg {
  background-color: red;
}

.Flyer--icon-type.Tag {
  background-color: green;
  margin-right: 0px;
  margin-right: 1px;
}

.Flyer--icon-type.Tag.dead {
  background-color: black;
  color: white;
}

.Flyer--icon-type.Tag.cancelled {
  background-color: orangered;
  margin-right: 1px;
}

.no-margin {
  margin: 0px;
  padding: 0px;
}
