.SettingsMenu {
  width: 100%;
  position: relative;
  z-index: 3;
  transition: .4s ease-in;
  -webkit-transform:translateZ(2px);
  -moz-transform:translateZ(2px);
  -o-transform:translateZ(2px);
  transform:translateZ(2px);
}

.SettingsMenu.collapsed {
  transform: translate(100%);
  position: absolute;
}

.SettingsMenu .MainHeader {
  display: flex;
  justify-content: left;
  align-content: center;
}

.SettingsMenu .Main--header--title {
  text-align: center;
  margin-bottom: 0;
  width: 100%;
}

.SettingsMenu a:not(.MainNavLink) {
  color: darkgrey;
  margin-top: 20px;
  display: inline-block;
}

.SettingsMenu a:not(.MainNavLink):hover, .SettingsMenu a:not(.MainNavLink):focus {
  color: white;
}

@media (max-width: 499px) {
  .SettingsMenu a:not(.MainNavLink) {
    width: 100%;
    text-align: center;
  }
}
