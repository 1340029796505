.SettingForm {
  padding: 0;
  display: flex;
  margin-bottom: 1px;
  padding: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.SettingForm .form-controls {
  width: auto;
  display: flex;
  /* align-self: flex-end; */
  align-self: center;
}

.SettingForm .form-controls button[type=reset], .SettingForm .form-controls button[type=submit] {
  margin-right: 0;
}

.SettingForm .formgroup {
  width: 200px;
  max-width: 100%;
  margin-right: 8px;
}

.SettingForm .formgroup.double {
  display: flex;
  width: 400px;
}

.SettingForm .formgroup.double fieldset:nth-of-type(1) {
  margin-right: 8px;
}

.SettingForm fieldset {
  margin-bottom: 0;
  width: 100%;
  display: inline-block;
}

.SettingForm label {
  max-width: auto;
  display: inline-block;
}

@media (max-width: 499px) {
  span.collapsed-value {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .SettingForm .formgroup.double {
    flex-direction: column;
    width: 200px;
  }

  .SettingForm .formgroup.double fieldset:nth-of-type(1){
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.SettingForm .form-controls button,
.SettingForm.collapsed button {
  color: white;
  opacity: .6;
  background-color: rgba(0, 0, 0, 0);
}

.SettingForm .form-controls button[type=submit]:not(:disabled){
 opacity: 1;
}

.SettingForm .form-controls button:focus,
.SettingForm.collapsed button:focus,
.SettingForm .form-controls button:hover:not(:disabled),
.SettingForm.collapsed button:hover {
  opacity: 1;
}
