.MainNavLink {
  display: inline-block;
  line-height: 24px;
  padding: 0px 8px;
  color: white;
  background-color: rgb(46, 46, 46);
  margin-left: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  text-decoration: none;
}

.MainHeaderNav--links .MainNavLink, .MainHeaderNav--links .MainNavLink {
  color: lightgray;
  background-color: rgba(0, 0, 0, 0);
}


.MainNavLink:hover, .MainNavLink:focus, .MainNavLink.whitesmoke-black.active {
  color: white;
  background-color: rgb(26, 26, 26);
}

/* .MainNavLink.whitesmoke-black.active {
  background-color: #131313;
  color: whitesmoke;
} */

.MainNavLink.green-white.active {
  background-color: green;
  color: white;
}

.MainNavLink.green-white {
  background-color: green;
  color: white;
}

.MainNavLink.blue-white.active {
  background-color: blue;
  color: white;
}

.MainNavLink.red-white.active {
  background-color: orangered;
  color: white;
}

.MainNavLink--count {
  position: relative;
  right: -2px;
  top: -1px;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
	border-radius: 4px;
  padding: 0px 4px;
  line-height: 14px;
  margin-left: 6px;
  background-color: orangered;
  text-align: center;
  color: white;
}
