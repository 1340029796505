.KillerToast--container {
  position: absolute;
  margin: auto;
  z-index: 6;
  color: white;
  width: 100%;
  height: 0;
  top: 36%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-self: center;
}

.KillerToast {
  -webkit-transition: all 0.2s ease-in-out, background-color 1ms ease-in-out .1s;
  -moz-transition: all 0.2s ease-in-out, background-color 1ms ease-in-out .1s;
  -o-transition: all 0.2s ease-in-out, background-color 1ms ease-in-out .1s;
  transition: all 0.2s ease-in-out, background-color 1ms ease-in-out .1s;
  transform: scale(0);
  padding: 0px 0px;
  font-weight: bold;
  position: absolute;
  width: 100%;
  max-width: 166px;
}

.KillerToast.on {
  -webkit-transition: scale(1);
  -moz-transition: scale(1);
  -o-transition: scale(1);
  transform: scale(1);
  padding: 16px 24px;
}

.KillerToast.success {
  background-color: green;
}

.KillerToast.error {
  background-color: red;
}

.KillerToast.dead {
  background-color: black;
}
