.Landing {
  width: 100%;
  min-height: 100%;
}

.Landing form {
  margin: 0px auto;
  max-width: 340px;
  padding: 48px;
}

.intro-about-container {
  background-color: whitesmoke;
  padding: 48px;
}

.intro-about-container h1 {
  text-align: center;
}

@media (max-width: 399px) {
  .intro-about-container {
    padding: 32px;
  }

  .Landing form {
    padding: 32px;
  }
}
