.BackLink:not(.header-link) {
  margin-top: 20px;
  color: silver;
  transition: ease;
  display: flex;
  align-items: center;
}

.BackLink span {
  margin-right: 4px;
}

.BackLink .Sword {
  position: relative;
  top: 1px;
}

.BackLink:hover {
  color: #2e2e2e;
}

.backlink-container {
  margin-left: 8px;
  margin-bottom: 24px;
}

.BackLink button{
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
}
