
.Avatar-small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.Main--avatar {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  margin-right: 8px;
}


.Avatar-small, .Main--avatar {
  background: no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}



