

.dashboard-header-container > h1 {
  position: relative;
  bottom: -33px;
}

.dashboard-header-container.avatar-section {
  margin-top: auto;
}

.Profile--location {
  position: relative;
  /* margin-left: 10px;
  top: 1px; */
  margin: 0px;
  height: 0px;
  /* top: 8px; */
}

.Profile .Profile--location svg {
  color: blue;
}

@media (max-width: 399px) {
  .Profile .Feed {
      margin-top: 32px;
    }

}
