.Feed {
  column-count: 1;
  column-gap: 0;
  max-width: 100%;
  /* height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
   -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.Feed .Card {
  break-inside: avoid-column;
}

.FetchingAdditional--container, .FetchingAdditional--container * {
   display: flex;
   /* text-align: left; */
   justify-content: center;
   align-items: center;
   width: 100%;
   max-height: 80px;
}

.FetchingAdditional--container a {
  height: 80px;
}


@media (min-width: 599px) {
  .Feed {
    column-count: 2;
    -moz-column-count: 2;
    column-gap: 16px;

  }
}

@media (min-width: 999px) {
  .Feed {
    column-count: 3;
    -moz-column-count: 3;
    column-gap: 16px;

  }
}


