.MainNav {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.MainNav:not(.not-sticky) {
  position: sticky;
  top: 1px;
}

.not-sticky  {
  position: relative;
  transition: top 0.3s;
}

.MainNav--links li {
  display: inline;
}

.MainNav--links {
  padding-left: 0;
  margin: 0;
  width: 100%;
}
