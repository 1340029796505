html,body {
  /* position: fixed; */
  height:100%;
  width: 100%;
  padding:0;
  margin:0;
}

#root {
  position: fixed;
  height: 100%;
  width: 100%;
}

body * {
    box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body form {
  padding: 24px;
  background-color: whitesmoke;
}

body form.dark-mode {
  background-color: rgb(46, 46, 46);
  color: white;
}

body form.header-form {
  max-width: 100%;
  padding: 16px;
  padding-top: 54px;
}

/* body p.Profile--location svg {
  color: blue;
} */


div.margin-bottom-8 {
  margin-bottom: 8px;
}

div.margin-bottom-16 {
  margin-bottom: 16px;
}

p.no-top-margin {
  margin-top: 0;
}

a {
  cursor: pointer;
}



body {
  font-family: 'Heebo', 'Arial';
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  background-color: dimgrey;
  font-size: 12px;
}

a {
  text-decoration: none;
   /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  transition: ease;
}

button, a {
  transition: .2s ease;
}

button:focus, a:focus, button:hover, a:hover {
  outline: none;
  box-shadow: none;
}

h1, h2{
  margin-top: 0;
  /* margin-bottom: 0; */
  padding-top: 0;
  font-size: 14px;
}


h3 {
  margin-top: 0;
  font-size: 12px;
}

ul li {
  list-style: none;
}

.App {
  width: 100%;
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.username::before {
  content: '@';
}

.html-display-p p {
  margin: 0;
  padding: 0;
}

.center-text {
  text-align: center;
}


.ActionButton {
  display: inline-block;
  line-height: 24px;
  padding: 0px 8px;
  color: white;
  background-color:green;
  margin-left: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  text-decoration: none;
}

.Landing .About a:not(.ActionButton),
.Landing .Intro a:not(.ActionButton),
.Landing form .form-controls a,
form .form-controls a:not(.ActionButton),
.FlyerCard .Accordian--content a,
.FlyerCard .Comment a  {
  color: blue;
}

.Landing .About a:not(.ActionButton),
.Landing .Intro a:not(.ActionButton){
  white-space: nowrap;
}

.Landing .About a:not(.ActionButton):hover,
.Landing .Intro a:not(.ActionButton):hover,
.Landing form a:hover,
form a:not(.ActionButton):hover
.FlyerCard .Accordian--content a:hover {
  color: rgba(46, 46, 46, 1);
  text-decoration: underline;
}

.FetchingAdditional--container a {
  /* color: rgba(46, 46, 46, 1); */
  color: darkgrey;
  cursor: pointer;
}

.FetchingAdditional--container a:hover {
  /* color: black; */
  color: whitesmoke
}


.white-smoke-bg {
  background-color: whitesmoke;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 1px;
}

.Tag, button {
  display: inline-block;
  line-height: 24px;
  max-height: 24px;
  padding: 0px 8px;
  margin-left: 0;
  border: none;
}

.Tag {
  color: white;
  background-color: darkgray;
  margin-right: 1px;
  margin-bottom: 1px;
}

.MainHeaderNav {
  height: 0;
  top: 1px;
  right: 1px;
  -webkit-transform:translateZ(2px);
  transform:translateZ(2px);
  z-index: 2;
}

.Dashboard .MainHeaderNav {
  position: relative;
}

.Landing .MainHeaderNav {
   position: relative;
   top: -133px;
}

.MainHeaderNav--links {
  display: flex;
  flex-shrink: 1;
  justify-content: flex-end;
  right: 1px;
  top: 1px;
}

.MainHeaderNav .MainNavLink.whitesmoke-black.active {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.MainHeaderNav .MainNavLink:hover, .MainHeaderNav .MainNavLink:focus  {
background-color: rgba(0, 0, 0, 0);
}

.MainNavLink {
  display: inline-block;
  line-height: 24px;
  padding: 0px 8px;
  color: white;
  background-color: rgb(46, 46, 46);
  margin-left: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  text-decoration: none;
}

.MainHeaderNav--links .MainNavLink, .MainHeaderNav--links .MainNavLink {
  color: lightgray;
  background-color: rgba(0, 0, 0, 0);
}


.MainNavLink:hover, .MainNavLink:focus, .MainNavLink.whitesmoke-black.active {
  color: white;
  background-color: rgb(26, 26, 26);
}

/* .MainNavLink.whitesmoke-black.active {
  background-color: #131313;
  color: whitesmoke;
} */

.MainNavLink.green-white.active {
  background-color: green;
  color: white;
}

.MainNavLink.green-white {
  background-color: green;
  color: white;
}

.MainNavLink.blue-white.active {
  background-color: blue;
  color: white;
}

.MainNavLink.red-white.active {
  background-color: orangered;
  color: white;
}

.MainNavLink--count {
  position: relative;
  right: -2px;
  top: -1px;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
	border-radius: 4px;
  padding: 0px 4px;
  line-height: 14px;
  margin-left: 6px;
  background-color: orangered;
  text-align: center;
  color: white;
}


.Avatar-small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.Main--avatar {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  margin-right: 8px;
}


.Avatar-small, .Main--avatar {
  background: no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}




/* .fadein {
  animation: fadein 2s;
}


.spinner {
  animation: spin 1.2s infinite linear;
}

.spinner svg {
  background: #f2f2f2;
  border-radius: 50%
}

@-webkit-keyframes spin {
  0%  {
    -webkit-transform: rotate(0deg)
  }
  100% {
    -webkit-transform: rotate(360deg)
  }
} */
.spinner {
  width: 60px;
  height: 60px;
  font-size: 24px;
  text-align: center;
}
.spinner svg {
  height: 40px;
  width: 40px;
  margin: 0 auto;
  /* display: flex;
  align-self: center; */
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}


form.dark-mode {
  background-color: rgb(46, 46, 46);
  color: white;
}

form.header-form {
  width: 100%;
  padding: 16px;
  padding-top: 54px;
}

legend {
  padding: 0;
  display: table;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  margin-bottom: 16px;
  min-width: 0;
}

/* legend, label:not(.SettingForm label) { */
  legend, label {
  font-size: 10px;
  line-height: 16px;
}

fieldset *{
  width: 100%;
}


body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}


input[type=text], input[type=datalist], input[type=password], input[type=email], div.textarea:read-write, select {
  background-color: white;
  border: solid 1px lightgrey;
  padding: 8px;
}

input[type=checkbox] {
  opacity: 0;
  z-index: 1;
}

span.checkbox input.checkbox {
  width: 100%;
  position: absolute;
}

span.checkbox {
  margin-left: 20px;
}

fieldset.checkbox input[type=checkbox] {
  height: 0;
  position: absolute;
}

fieldset.checkbox label, span.checkbox label {
  position: relative;
  display: flex;
}

fieldset.checkbox label {
  margin-left: 20px;
  width: -webkit-max-content;
  width: max-content;
  flex-grow: 1;
}

span.checkbox label {
  top: 2px;
}

fieldset.checkbox label::before, span.checkbox label::before {
  content: '';
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 1px solid darkgrey;
  background-color: white;
  position: absolute;
  top: 0px;
  left: -20px;
}

fieldset.checkbox label::after, span.checkbox label::after {
  content: none;
  display: inline-block;
  height: 12px;
  width: 9px;
  border-left: 1px solid;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  left: -15px;
  top: -2px;
}

fieldset.checkbox input[type=checkbox]:checked + label::after, span.checkbox input[type=checkbox]:checked + label::after {
  content: '';
}

fieldset.checkbox input[type=checkbox]:focus + label::before, span.checkbox input[type=checkbox]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

select {
  height: 31px;
}

.form-controls {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-controls button {
  margin-right: 8px;
}

.form-controls button:last-of-type {
  margin-right: 16px;
}

.form-controls button, .EventFieldset button {
  color: white;
  background-color: green;
}

.form-controls button:disabled, .EventFieldset button:disabled {
  opacity: .4;
}

.form-controls button[type='reset']  {
  color: rgb(46, 46, 46);
  background-color: rgba(0, 0, 0, 0);
}

.EditProfileForm button[type='reset'] {
  color: lightgray;
}

.form-controls button[type='reset']:hover, .form-controls button[type='reset']:focus  {
  color: black;
}

form.dark-mode .form-controls button[type='reset']:hover, form.dark-mode .form-controls button[type='reset']:focus  {
  color: white;
}

form.dark-mode button[type='reset']:hover, form.dark-mode button[type='reset']:focus {
  color: white;
}

/* intro form links centered below  */
form:not(.dark-mode) .form-controls {
  display: inline-block;
}

form:not(.EditProfileForm):not(.RecoverPasswordForm) .form-controls  a {
  display: inline-block;
  width: 100%;
  margin-top: 8px;
  text-align: center;
}

form:not(.EditProfileForm) .form-controls  > a:first-of-type {
  margin-top: 16px;
}

.fieldset-container {
  display: flex;
  max-width: 499px;
}

.fieldset-container fieldset:not(:last-child) {
  margin-right: 8px;
}

.tip::before {
  content: "Format Tip: "
}

/*  */
.CountryFieldset, .CityFieldset{
  /* width: auto; */
}

.CountryFieldset {
  width: 100%;
}

.RegionFieldset {
  width: 45%;
}

.CityFieldset{
  /* margin-left: 8px; */
  width: 100%;
}

div.react-select-container {
/* width: 100%; */
/* margin-right: inherit; */
display: inline-block;
/* margin-right: 8px; */
}

/* .CountryFieldset > select, .CityFieldset > input {
  width: 100%;
} */

fieldset.image-upload-fieldset * {
  margin-bottom: 0;
}

fieldset.grow {
  flex-grow: 1;
  /* width: 100%; */
}
fieldset.no-shrink {
  flex-shrink: 0;
}
fieldset.date {
  min-width: 86px;
  max-width: 86px;
}

.fieldset-container {
  width: 100%;
}

.fieldset-container-dates-venue {
  display: flex;
  flex-direction: row;
}

.fieldset-container-dates-venue *:not(div.react-select-container) {
  width: 100%;
}


.fieldset-container.sub-group {
  display: contents;
  margin-right: 8px;
}

.fieldset-container.sub-group.no-grow {
  flex-grow: 0;
}


.EventFieldset.condensed {
  height: 0;
  overflow: hidden;
}

.EventFieldset {
  height: auto;
  overflow: visible;
  background: lightgray;
  padding: 16px;
  margin-bottom: 16px;
}

@media (min-width: 799px){
  /* form > * {
    max-width: 666px;
    margin-left: auto;
    margin-right: auto;
  } */

}


@media (min-width: 399px) {

  .CityFieldset{
    margin-left: 0px;
  }

  .fieldset-container:not(.sub-group, .fieldset-container-dates-venue) {
    flex-direction: column;
  }

  .fieldset-container fieldset:not(:last-child) {
    margin-right: 0px;
  }


  .fieldset-container.sub-group fieldset:first-child {
    flex-grow: 2;
    margin-right: 8px;
  }

  .fieldset-container.sub-group fieldset:last-child {
    /* flex-grow: 1; */
    margin-right: 8px;
  }

  fieldset.grow {
    flex-grow: 1;
  }
  fieldset.no-shrink {
    flex-shrink: 0;
  }
  fieldset.date {
    width: inherit;
  }

}

@media (max-width: 399px) {
  .fieldset-container-dates-venue {
    flex-direction: column;
  }

  .fieldset-container.sub-group {
    display: flex;
    width: auto;
    margin-right: 0px;
  }
}

/*  */
legend.key {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: right;
  border: solid 1px lightgray;
  padding: 8px;
}

div.textarea {
  border: ridge 2px;
  min-height: 5em;
  overflow: auto;
}

/* Temp placement until component made */
.AvatarPreview {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AvatarPreview * {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.AvatarPreview > label > div > span {
  color: white;
  /* text-align: center; */
  justify-content: center;
  font-size: 10px;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(175deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .5));
}


.FlyerPreview, .FlyerPreview > * {
  position: relative;
}

.FlyerPreview > span {
  background: linear-gradient(175deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .5));
  position: absolute;
  top: 0;
  border-radius: 0;
  height: calc(100% - 4px);
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.EventsPreview li {
  margin-bottom: 16px;
}

.EventsPreview li > * {
  display: flex;
  position: relative;
}

.EventsPreview li > span{
  margin-bottom: 4px;
}

.EventsPreview li span i {
  color: darkgrey;
  font-style: normal;
}

.date-i, .city-i, .venue-i {
  width: 20px;
}

.EventsPreview .date-i::before {
  font-family: "FontAwesome";
  content: '\F133';
  color: darkgray;
}

.EventsPreview .city-i::before {
  font-family: "FontAwesome";
  content: '\F041';
  color: darkgray;
}

.EventsPreview .venue-i::before {
  font-family: "FontAwesome";
  content: '\F276';
  color: darkgray;
}

.EventsPreview .delete-i {
  /* margin-right: 32px; */
  position: relative;
  left: -32px;
  height: 0;
  color: orangered;
}

li.cancelled span *:not(label) {
  text-decoration: line-through;
}



.EditProfileForm .Profile--location {
  position: relative;
  top: -36px;
  left: 74px;
}

.EditProfileForm .Profile--location svg {
  color: blue;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ValidationError {
  font-size: 10px;
  color: orangered;
}

.MainHeader {
  padding: 16px;
  margin-bottom: 1px;
  color: white;
  height: 67px;
  display: flex;
  align-items: center;
  background-color: rgb(46, 46, 46);
  width: 100%;
  z-index: 1;
}

.MainHeader.dbl-height {
  height: 134px;
}

.MainHeader.no-height {
  height: 0;
  padding: 0;
}

.MainHeader .header-link button, a.header-link {
  color: white;
  font-size: 14px;
}

.Feed {
  -webkit-column-count: 1;
          column-count: 1;
  grid-column-gap: 0;
  -webkit-column-gap: 0;
          column-gap: 0;
  max-width: 100%;
  /* height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
  transition: all 0.4s ease-in-out;
}

.Feed .Card {
  -webkit-column-break-inside: avoid;
          break-inside: avoid-column;
}

.FetchingAdditional--container, .FetchingAdditional--container * {
   display: flex;
   /* text-align: left; */
   justify-content: center;
   align-items: center;
   width: 100%;
   max-height: 80px;
}

.FetchingAdditional--container a {
  height: 80px;
}


@media (min-width: 599px) {
  .Feed {
    -webkit-column-count: 2;
            column-count: 2;
    -moz-column-count: 2;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;

  }
}

@media (min-width: 999px) {
  .Feed {
    -webkit-column-count: 3;
            column-count: 3;
    -moz-column-count: 3;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;

  }
}



.Accordian {
  cursor: pointer;
  width: 100%;
  opacity: .5;
}

.Accordian > * {
  margin-top: 0px;
}


.Accordian:hover, .Accordian *:hover {
  opacity: 1;
}

.Accordian.active {
  opacity: .5;
}

.Accordian--content, .Accordian--content * {
  overflow: hidden;
  max-height: 0;
  transition: all 0.4s ease-in-out;
}

.Accordian--content.active, .Accordian--content.active * {
  max-height: 2000px;
  transition: all 0.4s ease-in-out;
}

.Accordian--content p:first-child {
  margin-top: 0px;
}

.Comment {
  border-top: solid 1px dimgrey;
  padding-top: 16px;
  padding-bottom: 16px;
}

.Comment--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Comment--handle {
  margin-bottom: 0;
  margin-left: 8px;
}

.Comment span {
  font-size: 10px;
  font-weight: normal;
}

.Comment--comment {
  margin-bottom: 0;
}

.Comment--isCreator {
  margin-left: 4px;
}

.Comment--modified-at {
 text-align: right;
}


.Comments {
  padding-left: 16px;
  padding-right: 16px;

}



.FlyerCardMenu {
  position: absolute;
  right: 10px;
  height: auto;
  width: calc(100% - 26px);
  display: flex;
  /* justify-content: flex-end; */
  justify-content: space-between;
  background-color: whitesmoke;
  z-index: 1;
}

.FlyerForm .FlyerCardMenu {
  position: inherit;
  display: inline;
}

.FlyerCardMenuClosed {
  position: relative;
}

.FlyerCardMenuClosed .handle {
  position: absolute;
  right: -6px;
}

.handle {
  padding: 6px 10px;
  /* width: 100%; */
  text-align: right;
  color: rgb(46, 46, 46);
  /* color: whitesmoke; */
  /* background-color: whitesmoke; */
  /* background-image: linear-gradient(0deg, rgba(0,0,0,0), rgba(255,255,255,.3)); */
}

.FlyerCardMenu .active {
  color: green;
}

.inline-float-right {
  display: inline-table;
  float: right;
}

.MainNav {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.MainNav:not(.not-sticky) {
  position: -webkit-sticky;
  position: sticky;
  top: 1px;
}

.not-sticky  {
  position: relative;
  transition: top 0.3s;
}

.MainNav--links li {
  display: inline;
}

.MainNav--links {
  padding-left: 0;
  margin: 0;
  width: 100%;
}

.FlyerCard.cancelled .Card--body h2,
.FlyerCard.cancelled .Card--body .Flyer--dates,
.FlyerCard.cancelled .Card--body .Flyer--location,
.FlyerCard.cancelled .Card--body .Accordian--content {
  text-decoration: line-through;
}

.Card {
  margin-bottom: 20px;
  background: whitesmoke;
}

.Card--body {
  padding: 16px;
  padding-bottom: 0;
  position: relative;
}

.Card--header i {
  line-height: 24px;
  font-size: 22px;
}

.Card--header h2 {
  margin-bottom: 12px;
}

.Card--header .Tag {
  margin-bottom: 8px;
}

.Card .Accordian {
  margin-top: 12px;
}

/* Flyer */
.Flyer--image {
  width: 100%;
}

.Flyer--dates svg {
  margin-right: 4px;
  color: orangered;
}

.Flyer--location svg {
  margin-right: 4px;
  color: blue;
}

.Flyer--location.cancelled {
  text-decoration: line-through;
}

.DateOf {
  margin-right: 4px;
}

/* .Flyer--dates, .Flyer--location {
  margin: 10px 0;
} */


.Flyer--icon-status.Tag {
  background-color: red;
  margin-right: 4px;
}

.red-bg {
  background-color: red;
}

.Flyer--icon-type.Tag {
  background-color: green;
  margin-right: 0px;
  margin-right: 1px;
}

.Flyer--icon-type.Tag.dead {
  background-color: black;
  color: white;
}

.Flyer--icon-type.Tag.cancelled {
  background-color: orangered;
  margin-right: 1px;
}

.no-margin {
  margin: 0px;
  padding: 0px;
}

.NotFound {
  height: 100%;
  width: 100%;
  background: no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

}

.NotFound > div {
  background: linear-gradient(175deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .5));
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}



.dashboard-header-container > h1 {
  position: relative;
  bottom: -33px;
}

.dashboard-header-container.avatar-section {
  margin-top: auto;
}

.Profile--location {
  position: relative;
  /* margin-left: 10px;
  top: 1px; */
  margin: 0px;
  height: 0px;
  /* top: 8px; */
}

.Profile .Profile--location svg {
  color: blue;
}

@media (max-width: 399px) {
  .Profile .Feed {
      margin-top: 32px;
    }

}

.Dashboard {
  min-height: 100%;
}




.SettingsMenu {
  width: 100%;
  position: relative;
  z-index: 3;
  transition: .4s ease-in;
  -webkit-transform:translateZ(2px);
  transform:translateZ(2px);
}

.SettingsMenu.collapsed {
  -webkit-transform: translate(100%);
          transform: translate(100%);
  position: absolute;
}

.SettingsMenu .MainHeader {
  display: flex;
  justify-content: left;
  align-content: center;
}

.SettingsMenu .Main--header--title {
  text-align: center;
  margin-bottom: 0;
  width: 100%;
}

.SettingsMenu a:not(.MainNavLink) {
  color: darkgrey;
  margin-top: 20px;
  display: inline-block;
}

.SettingsMenu a:not(.MainNavLink):hover, .SettingsMenu a:not(.MainNavLink):focus {
  color: white;
}

@media (max-width: 499px) {
  .SettingsMenu a:not(.MainNavLink) {
    width: 100%;
    text-align: center;
  }
}

.SettingForm {
  padding: 0;
  display: flex;
  margin-bottom: 1px;
  padding: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.SettingForm .form-controls {
  width: auto;
  display: flex;
  /* align-self: flex-end; */
  align-self: center;
}

.SettingForm .form-controls button[type=reset], .SettingForm .form-controls button[type=submit] {
  margin-right: 0;
}

.SettingForm .formgroup {
  width: 200px;
  max-width: 100%;
  margin-right: 8px;
}

.SettingForm .formgroup.double {
  display: flex;
  width: 400px;
}

.SettingForm .formgroup.double fieldset:nth-of-type(1) {
  margin-right: 8px;
}

.SettingForm fieldset {
  margin-bottom: 0;
  width: 100%;
  display: inline-block;
}

.SettingForm label {
  max-width: auto;
  display: inline-block;
}

@media (max-width: 499px) {
  span.collapsed-value {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .SettingForm .formgroup.double {
    flex-direction: column;
    width: 200px;
  }

  .SettingForm .formgroup.double fieldset:nth-of-type(1){
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.SettingForm .form-controls button,
.SettingForm.collapsed button {
  color: white;
  opacity: .6;
  background-color: rgba(0, 0, 0, 0);
}

.SettingForm .form-controls button[type=submit]:not(:disabled){
 opacity: 1;
}

.SettingForm .form-controls button:focus,
.SettingForm.collapsed button:focus,
.SettingForm .form-controls button:hover:not(:disabled),
.SettingForm.collapsed button:hover {
  opacity: 1;
}

.BackLink:not(.header-link) {
  margin-top: 20px;
  color: silver;
  transition: ease;
  display: flex;
  align-items: center;
}

.BackLink span {
  margin-right: 4px;
}

.BackLink .Sword {
  position: relative;
  top: 1px;
}

.BackLink:hover {
  color: #2e2e2e;
}

.backlink-container {
  margin-left: 8px;
  margin-bottom: 24px;
}

.BackLink button{
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
}

.Sword {
  display: inline-block;
}

.right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);

}

.left {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);

}

.down {
   -webkit-transform: rotate(-90deg);
           transform: rotate(-90deg);
}

.fortyfive-down {
   -webkit-transform: rotate(225deg);
           transform: rotate(225deg);
}

.down-right-angled {
   -webkit-transform: rotate(190deg);
           transform: rotate(190deg);
}

.sword-underline {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}



.sword-underline :first-child {
  margin-left: 12px;
}

.CountryRegionsNav {
  -webkit-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
  position: relative;
}

.Landing {
  width: 100%;
  min-height: 100%;
}

.Landing form {
  margin: 0px auto;
  max-width: 340px;
  padding: 48px;
}

.intro-about-container {
  background-color: whitesmoke;
  padding: 48px;
}

.intro-about-container h1 {
  text-align: center;
}

@media (max-width: 399px) {
  .intro-about-container {
    padding: 32px;
  }

  .Landing form {
    padding: 32px;
  }
}

.CentralContainer {
  margin: 0 16px;
  margin-top: 16px;
  margin-bottom: 80px;
  max-width: 500px;
  /* padding: 40px; */
}

/* .CentralContainer section:not(:last-child) {
  margin-bottom: 32px;
} */
@media (max-width: 499px) {
  .Main--content .CentralContainer {
    margin: 0;
  }
}

@media (min-width: 499px) {
  .CentralContainer {
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 50px;
  }
}

.Intro h3 {
  margin-top: auto;
}

.Intro .CentralContainer h3 {
   margin-bottom: 9.38px;
}

.Intro p {
  margin-top: 0;
}


.flex-bullets {
  display: flex;
  flex-direction: column;
  align-items: top;
}

.flex-bullets .Sword {
  position: relative;
  top: 10px;
  margin-right: 2px;
  align-self: center;
}

.flex-bullets .flourish {
  position: relative;
  top: -6px;
  align-self: center;
}

.flourish {
  width: 32px;
  align-self: center;
}


.center-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top:16px;
  margin-bottom: 8px;
}

.Footer {
  text-align: center;
  font-size: 11px;
  /* display: flex; */
  padding: 16px 0;
  /* position: relative; */
  /* position: absolute; */
  bottom: 0;
  width: 100%;
}

.Menu {
  z-index: 5;
  -webkit-transform: translate3d(0,0,0);
}

.Menu--pal {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: .4s ease;
}

.Menu--pal--btn.active {
  background-color: rgb(46, 46, 46);
}

.Menu--pal--btn:hover {
 background-color: #909090;
}

.Menu--pal--btn.active:hover {
 background-color: #131313;
}

.Menu--pal--btn {
  background-color: darkgray;
  /* color: dimgrey; */
  color: white;
  /* width: 100%; */
  height: 66px;
  padding: 0;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  text-align: center;
}



.Menu--pal--btn .i {
  font-size: 22px;
  margin: 4px;
}



@media (max-width: 399px) {

  .Menu--pal {
    flex-direction: row;
  }

  .Menu--pal > * {
    width:100%;
  }

}


.Main {
  height: 100%;
}

.Main--header--title {
  font-weight: normal;
}

.Main--content {
  margin: 16px;
  height: 100%;
  /* padding: 16px 16px 60px 16px; */
  margin-bottom: 40px;
}

.Main--content.no-margin {
  margin: 0;
}

@media (max-width: 399px) {
  .Main--content {
    margin: 8px;
    margin-bottom: 20px;
  }

  .Main--content.no-margin {
    margin: 0;
  }

  .Main--header {
    padding: 12px;
  }
}


.AuthedSplit {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  /* overflow: scroll; */
}

/* .Menu, .Main {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
} */

.Menu {
  width: 72px;
  padding: 1px;
}

.Main {
  width: 100%;
}


@media (max-width: 399px) {

  .Menu {
    position: fixed;
    bottom: 0px;
    height: auto;
    width: 100%;
    padding-bottom: 0;
  }

  .Main {
    padding-bottom: 76px;
  }

}

.KillerToast--container {
  position: absolute;
  margin: auto;
  z-index: 6;
  color: white;
  width: 100%;
  height: 0;
  top: 36%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-self: center;
}

.KillerToast {
  transition: all 0.2s ease-in-out, background-color 1ms ease-in-out .1s;
  -webkit-transform: scale(0);
          transform: scale(0);
  padding: 0px 0px;
  font-weight: bold;
  position: absolute;
  width: 100%;
  max-width: 166px;
}

.KillerToast.on {
  -webkit-transition: scale(1);
  -moz-transition: scale(1);
  -o-transition: scale(1);
  -webkit-transform: scale(1);
          transform: scale(1);
  padding: 16px 24px;
}

.KillerToast.success {
  background-color: green;
}

.KillerToast.error {
  background-color: red;
}

.KillerToast.dead {
  background-color: black;
}

