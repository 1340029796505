.Menu {
  z-index: 5;
  -webkit-transform: translate3d(0,0,0);
}

.Menu--pal {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: .4s ease;
}

.Menu--pal--btn.active {
  background-color: rgb(46, 46, 46);
}

.Menu--pal--btn:hover {
 background-color: #909090;
}

.Menu--pal--btn.active:hover {
 background-color: #131313;
}

.Menu--pal--btn {
  background-color: darkgray;
  /* color: dimgrey; */
  color: white;
  /* width: 100%; */
  height: 66px;
  padding: 0;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  text-align: center;
}



.Menu--pal--btn .i {
  font-size: 22px;
  margin: 4px;
}



@media (max-width: 399px) {

  .Menu--pal {
    flex-direction: row;
  }

  .Menu--pal > * {
    width:100%;
  }

}

