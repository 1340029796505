.CentralContainer {
  margin: 0 16px;
  margin-top: 16px;
  margin-bottom: 80px;
  max-width: 500px;
  /* padding: 40px; */
}

/* .CentralContainer section:not(:last-child) {
  margin-bottom: 32px;
} */
@media (max-width: 499px) {
  .Main--content .CentralContainer {
    margin: 0;
  }
}

@media (min-width: 499px) {
  .CentralContainer {
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 50px;
  }
}
