.MainHeaderNav {
  height: 0;
  top: 1px;
  right: 1px;
  -webkit-transform:translateZ(2px);
  -moz-transform:translateZ(2px);
  -o-transform:translateZ(2px);
  transform:translateZ(2px);
  z-index: 2;
}

.Dashboard .MainHeaderNav {
  position: relative;
}

.Landing .MainHeaderNav {
   position: relative;
   top: -133px;
}

.MainHeaderNav--links {
  display: flex;
  flex-shrink: 1;
  justify-content: flex-end;
  right: 1px;
  top: 1px;
}

.MainHeaderNav .MainNavLink.whitesmoke-black.active {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.MainHeaderNav .MainNavLink:hover, .MainHeaderNav .MainNavLink:focus  {
background-color: rgba(0, 0, 0, 0);
}
