
form.dark-mode {
  background-color: rgb(46, 46, 46);
  color: white;
}

form.header-form {
  width: 100%;
  padding: 16px;
  padding-top: 54px;
}

legend {
  padding: 0;
  display: table;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  margin-bottom: 16px;
  min-width: 0;
}

/* legend, label:not(.SettingForm label) { */
  legend, label {
  font-size: 10px;
  line-height: 16px;
}

fieldset *{
  width: 100%;
}


body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}


input[type=text], input[type=datalist], input[type=password], input[type=email], div.textarea:read-write, select {
  background-color: white;
  border: solid 1px lightgrey;
  padding: 8px;
}

input[type=checkbox] {
  opacity: 0;
  z-index: 1;
}

span.checkbox input.checkbox {
  width: 100%;
  position: absolute;
}

span.checkbox {
  margin-left: 20px;
}

fieldset.checkbox input[type=checkbox] {
  height: 0;
  position: absolute;
}

fieldset.checkbox label, span.checkbox label {
  position: relative;
  display: flex;
}

fieldset.checkbox label {
  margin-left: 20px;
  width: max-content;
  flex-grow: 1;
}

span.checkbox label {
  top: 2px;
}

fieldset.checkbox label::before, span.checkbox label::before {
  content: '';
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 1px solid darkgrey;
  background-color: white;
  position: absolute;
  top: 0px;
  left: -20px;
}

fieldset.checkbox label::after, span.checkbox label::after {
  content: none;
  display: inline-block;
  height: 12px;
  width: 9px;
  border-left: 1px solid;
  transform: rotate(-45deg);
  position: absolute;
  left: -15px;
  top: -2px;
}

fieldset.checkbox input[type=checkbox]:checked + label::after, span.checkbox input[type=checkbox]:checked + label::after {
  content: '';
}

fieldset.checkbox input[type=checkbox]:focus + label::before, span.checkbox input[type=checkbox]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

select {
  height: 31px;
}

.form-controls {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-controls button {
  margin-right: 8px;
}

.form-controls button:last-of-type {
  margin-right: 16px;
}

.form-controls button, .EventFieldset button {
  color: white;
  background-color: green;
}

.form-controls button:disabled, .EventFieldset button:disabled {
  opacity: .4;
}

.form-controls button[type='reset']  {
  color: rgb(46, 46, 46);
  background-color: rgba(0, 0, 0, 0);
}

.EditProfileForm button[type='reset'] {
  color: lightgray;
}

.form-controls button[type='reset']:hover, .form-controls button[type='reset']:focus  {
  color: black;
}

form.dark-mode .form-controls button[type='reset']:hover, form.dark-mode .form-controls button[type='reset']:focus  {
  color: white;
}

form.dark-mode button[type='reset']:hover, form.dark-mode button[type='reset']:focus {
  color: white;
}

/* intro form links centered below  */
form:not(.dark-mode) .form-controls {
  display: inline-block;
}

form:not(.EditProfileForm):not(.RecoverPasswordForm) .form-controls  a {
  display: inline-block;
  width: 100%;
  margin-top: 8px;
  text-align: center;
}

form:not(.EditProfileForm) .form-controls  > a:first-of-type {
  margin-top: 16px;
}

.fieldset-container {
  display: flex;
  max-width: 499px;
}

.fieldset-container fieldset:not(:last-child) {
  margin-right: 8px;
}

.tip::before {
  content: "Format Tip: "
}

/*  */
.CountryFieldset, .CityFieldset{
  /* width: auto; */
}

.CountryFieldset {
  width: 100%;
}

.RegionFieldset {
  width: 45%;
}

.CityFieldset{
  /* margin-left: 8px; */
  width: 100%;
}

div.react-select-container {
/* width: 100%; */
/* margin-right: inherit; */
display: inline-block;
/* margin-right: 8px; */
}

/* .CountryFieldset > select, .CityFieldset > input {
  width: 100%;
} */

fieldset.image-upload-fieldset * {
  margin-bottom: 0;
}

fieldset.grow {
  flex-grow: 1;
  /* width: 100%; */
}
fieldset.no-shrink {
  flex-shrink: 0;
}
fieldset.date {
  min-width: 86px;
  max-width: 86px;
}

.fieldset-container {
  width: 100%;
}

.fieldset-container-dates-venue {
  display: flex;
  flex-direction: row;
}

.fieldset-container-dates-venue *:not(div.react-select-container) {
  width: 100%;
}


.fieldset-container.sub-group {
  display: contents;
  margin-right: 8px;
}

.fieldset-container.sub-group.no-grow {
  flex-grow: 0;
}


.EventFieldset.condensed {
  height: 0;
  overflow: hidden;
}

.EventFieldset {
  height: auto;
  overflow: visible;
  background: lightgray;
  padding: 16px;
  margin-bottom: 16px;
}

@media (min-width: 799px){
  /* form > * {
    max-width: 666px;
    margin-left: auto;
    margin-right: auto;
  } */

}


@media (min-width: 399px) {

  .CityFieldset{
    margin-left: 0px;
  }

  .fieldset-container:not(.sub-group, .fieldset-container-dates-venue) {
    flex-direction: column;
  }

  .fieldset-container fieldset:not(:last-child) {
    margin-right: 0px;
  }


  .fieldset-container.sub-group fieldset:first-child {
    flex-grow: 2;
    margin-right: 8px;
  }

  .fieldset-container.sub-group fieldset:last-child {
    /* flex-grow: 1; */
    margin-right: 8px;
  }

  fieldset.grow {
    flex-grow: 1;
  }
  fieldset.no-shrink {
    flex-shrink: 0;
  }
  fieldset.date {
    width: inherit;
  }

}

@media (max-width: 399px) {
  .fieldset-container-dates-venue {
    flex-direction: column;
  }

  .fieldset-container.sub-group {
    display: flex;
    width: auto;
    margin-right: 0px;
  }
}

/*  */
legend.key {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: right;
  border: solid 1px lightgray;
  padding: 8px;
}

div.textarea {
  border: ridge 2px;
  min-height: 5em;
  overflow: auto;
}

/* Temp placement until component made */
.AvatarPreview {
  width: fit-content;
}

.AvatarPreview * {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.AvatarPreview > label > div > span {
  color: white;
  /* text-align: center; */
  justify-content: center;
  font-size: 10px;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(175deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .5));
}


.FlyerPreview, .FlyerPreview > * {
  position: relative;
}

.FlyerPreview > span {
  background: linear-gradient(175deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .5));
  position: absolute;
  top: 0;
  border-radius: 0;
  height: calc(100% - 4px);
  min-width: fit-content;
  min-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.EventsPreview li {
  margin-bottom: 16px;
}

.EventsPreview li > * {
  display: flex;
  position: relative;
}

.EventsPreview li > span{
  margin-bottom: 4px;
}

.EventsPreview li span i {
  color: darkgrey;
  font-style: normal;
}

.date-i, .city-i, .venue-i {
  width: 20px;
}

.EventsPreview .date-i::before {
  font-family: "FontAwesome";
  content: '\f133';
  color: darkgray;
}

.EventsPreview .city-i::before {
  font-family: "FontAwesome";
  content: '\f041';
  color: darkgray;
}

.EventsPreview .venue-i::before {
  font-family: "FontAwesome";
  content: '\f276';
  color: darkgray;
}

.EventsPreview .delete-i {
  /* margin-right: 32px; */
  position: relative;
  left: -32px;
  height: 0;
  color: orangered;
}

li.cancelled span *:not(label) {
  text-decoration: line-through;
}


