.Main {
  height: 100%;
}

.Main--header--title {
  font-weight: normal;
}

.Main--content {
  margin: 16px;
  height: 100%;
  /* padding: 16px 16px 60px 16px; */
  margin-bottom: 40px;
}

.Main--content.no-margin {
  margin: 0;
}

@media (max-width: 399px) {
  .Main--content {
    margin: 8px;
    margin-bottom: 20px;
  }

  .Main--content.no-margin {
    margin: 0;
  }

  .Main--header {
    padding: 12px;
  }
}

