.NotFound {
  height: 100%;
  width: 100%;
  background: no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

}

.NotFound > div {
  background: linear-gradient(175deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .5));
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  min-width: fit-content;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
